import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Arrow from "../images/arrow.inline.svg";
import IconOne from "../images/icon5.inline.svg";
import IconTwo from "../images/icon2.inline.svg";
import IconThree from "../images/icon1.inline.svg";
import IconFour from "../images/icon6.inline.svg";
import IconFive from "../images/icon4.inline.svg";
import IconSix from "../images/icon3.inline.svg";

import IconEight from "../images/icon8.inline.svg";
import IconNine from "../images/icon9.inline.svg";

import Goverments from "../images/govermentstwo.inline.svg";

function IndexPage({ data, pageContext: { lang, currentPath } }) {
  return (
    <Layout>
      <SEO
        title="Government Solutions"
        description="LeverID provides safe, secure, and inclusive access to a variety of government services for each citizen and resident."
        image="https://www.leverid.com/thumbnail-new.png"
      />

      <Container className="home-first fullwidth banner-section regularheader">
        <Row className="container">
          <Col>
            <h4>GOVERNMENTS</h4>
            <h1>Enable Secure Access to Digital Government Services</h1>
            <p>
              LeverID provides safe, secure, and inclusive access to a variety
              of government services for each citizen and resident.
            </p>
            <Link to="/contact">
              Get in touch
              <Arrow />
            </Link>
          </Col>
          <Col className="bannerimage">
            <Img fluid={data.govermentheader.childImageSharp.fluid} />
          </Col>
        </Row>
      </Container>

      <Container className="home-third  fullwidth bluelines">
        <Container>
          <Row>
            <h2>
              Why do Governments
              <br /> choose LeverID?
            </h2>
          </Row>
          <Row>
            <Col>
              <div className="element-icon">
                <IconOne />
              </div>
              <h4>Post-Quantum Capable Design</h4>
              <p>
                The advent of quantum computing brings new security challenges.
                With this in mind, LeverID has been tailored to feature quantum
                capability.
              </p>
            </Col>
            <Col>
              <div className="element-icon">
                <IconTwo />
              </div>
              <h4>Largely Scalable</h4>
              <p>
                Product design and infrastructure configuration enable vast
                scalability even by utilization of hundreds of millions of
                users.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="element-icon">
                <IconThree />
              </div>
              <h4>Attack-Tolerant and Reliable</h4>
              <p>
                LeverID features distributed private key implementation,
                ensuring no service breaks and reliable defense against threats.
              </p>
            </Col>
            <Col>
              <div className="element-icon">
                <IconFour />
              </div>
              <h4>Fast Transaction Speed</h4>
              <p>
                Whether it’s authentication or signing, transactions speeds
                remain consistently swifter than any RSA-based solution.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="element-icon">
                <IconEight />
              </div>
              <h4>Fully Customisable Approach</h4>
              <p>
                A modular solution with white label readiness to ensure the
                product can be configured to suit a specific country.{" "}
              </p>
            </Col>
            <Col>
              <div className="element-icon">
                <IconNine />
              </div>
              <h4>Universal Login</h4>
              <p>
                Enable citizens and residents access to different digital
                government services via one universal login.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="element-icon">
                <IconFive />
              </div>
              <h4>Ease of Integration</h4>
              <p>
                Being technically documented, ease of integration is enabled
                through API or a software development kit (SDK) for clients
                wishing to integrate LeverID.
              </p>
            </Col>
            <Col>
              <div className="element-icon">
                <IconSix />
              </div>
              <h4>Mobile-First Design</h4>
              <p>
                Designed with mobile users in mind, our solution allows our
                users to effortlessly authenticate and sign from anywhere in the
                world.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container className="fullwidth government-graph">
        <Container>
          <Row>
            <h3>LeverID is designed for interoperability</h3>
            <p>
              Once a person has verified their identity with LeverID, they will
              never have to re-verify their identity again across any government
              institution where LeverID is integrated.
            </p>
            <Goverments />
          </Row>
        </Container>
      </Container>

      <Container className="fullwidth home-fift centered-text">
        <Container>
          <Row>
            <Col>
              <h3>Easy-to-Use for Everyone</h3>
              <p>
                It’s easy for people of all demographics and social groups to
                use LeverID to{" "}
                <strong>
                  authenticate their identity, sign documents and confirm
                  transactions.
                </strong>
              </p>
            </Col>
            <Col>
              <Img fluid={data.goverthree.childImageSharp.fluid} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Img fluid={data.govertwo.childImageSharp.fluid} />
            </Col>
            <Col>
              <h3>Designed to Withstand the Test of Time</h3>
              <p>
                Most ID solutions are based on RSA cryptography, that was
                invented in 1977. With the rapid development of microchips, we
                will soon see quantum computers that will be able to break most
                RSA encryptions, creating a huge national security threat.
              </p>
              <p>
                LeverID is based on elliptical curve cryptography, that coupled
                with modularity, enables the replacement of different
                cryptography standards within the system. Such capability allows
                LeverID to <strong>withstand the test of time.</strong>
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Based on Patent-Pending Technology</h3>
              <p>
                LeverID is based on patent-pending technology, thereby forging
                the path for{" "}
                <strong>
                  new industry standards, as well as yielding sustainability.
                </strong>
              </p>
            </Col>
            <Col>
              <Img fluid={data.goverone.childImageSharp.fluid} />
            </Col>
          </Row>
        </Container>
      </Container>

      <div id="prefooter">
        <Container>
          <Row>
            <Col>
              <Img fluid={data.rihofull.childImageSharp.fluid} />
            </Col>
            <Col>
              <h3>
                Explore how your business
                <br /> can reap the benefits of LeverID
              </h3>
              <p>
                To learn more about how LeverID could serve your needs, please
                reach out using the button below.
              </p>
              <Link to="/contact">
                Contact us <Arrow />
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    rihofull: file(relativePath: { eq: "riho_full.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    govermentheader: file(relativePath: { eq: "govermentheader.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    goverone: file(relativePath: { eq: "goverone.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    govertwo: file(relativePath: { eq: "govertwo.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    goverthree: file(relativePath: { eq: "goverthree.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export default IndexPage;
